<template>
    <div class="row">
        <p>Site en construction, rendez-vous <a href="https://www.consonormandie.fr">ici</a></p>
    </div>
</template>

<script>
    export default {
        name: 'HomePage',
        components: {
        },
        //Chaque fois que cette vue est montée, on charge les listes via l'API s'il y a un token Bearer et si la TodoList est vide
        mounted() {
        },
        methods: {
        },
        computed: {
        },
    }
</script>

<style scoped>
</style>