import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PartnersList from '@/views/PartnersList.vue';


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  
  {
    path: '/Partners',
    name: 'PartnersList',
    component: PartnersList,
  },

  { 
    path: '/:pathMatch(.*)*', redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes,
});

export default router;
