<template>
  <div class='row nav'>
    <div class="col-6"><router-link to='/'>Home</router-link></div>
    <div class="col-6"><router-link to='/Partners'>Partners</router-link></div>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'NavigationBar',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  text-align: center;
}
</style>
