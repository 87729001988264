<template>
  <footer>

  </footer>
</template>

<script>
export default {
  name: 'FooterBar',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
