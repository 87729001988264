<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: 'PartnersList',
        components: {
        },
        methods: {
        },
        computed: {
        }
    }
</script>